<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">{{ dynamicLabel }}</v-card-title>
        <v-card-text v-if="page !== 'interaction'">
          <v-btn color="primary" @click="clickAddNewLine()">Adicionar Linha</v-btn>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col v-show="!loading" cols="12" lg="6" v-for="(lineSelected, index) in lineSelect" :key="index">
      <v-card outlined class="mx-auto">
        <v-card-title class="primary white--text">
          <div class="headerClass">
            {{ lineSelected.line.ddd ? `${lineSelected.line.ddi} (${lineSelected.line.ddd}) ${lineSelected.line.nr_linha}` : 'Selecione uma Linha' }}
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="page !== 'interaction'" :disabled="unremovable" icon @click="closeLine(index)" class="mx-3 mt-2">
            <v-icon :style="disabledBtnStyle">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <LineComponent
                :readonly="isReadonlyByPage"
                :selectedLine="lineSelected.line"
                :rules="objectRule"
                @save="saveComponentField($event, 'line', index)"
              ></LineComponent>

              <v-text-field
                v-if="desactiveComponentNewIccid"
                label="ICCID novo SIM Card"
                outlined
                dense
                :rules="iccidRule"
                v-model="lineSelected.customIccid"
              >
              </v-text-field>

              <v-text-field
                v-if="page === 'validate' && lineSelected.customIccid"
                :readonly="true"
                label="ICCID solicitado"
                outlined
                dense
                v-model="lineSelected.customIccid"
              >
              </v-text-field>

              <IccidField
                v-if="!desactiveComponentNewIccid"
                :ddd="lineSelected.line.ddd"
                :rules="objectRule"
                :chipSelected="lineSelected.iccid"
                :readOnlyField="isReadonlyByPage"
                @changeData="saveComponentField($event, 'iccid', index)"
              ></IccidField>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import IccidField from "@/components/fields/IccidField.vue";
import LineComponent from "@/components/forms/LineComponent.vue";

export default {
  components: { IccidField, LineComponent },
  props: {
    page: {
      Type: String,
      default: ""
    },
    isGroupPessoa: {
      page: {
        Type: Boolean,
        default: false
      }
    },
    index: {
      page: {
        Type: Number,
        default: 0
      }
    }
  },
  data() {
    return {
      lineSelect: [],
      loading: true,
      unremovable: false,
      desactiveComponentNewIccid: false,
      iccids: [],
      show: false,
      objectRule: [v => (v && Object.keys(v).length > 0) || 'Obrigatório'],
      iccidField: [],
      storageKey: "change_chip_saved_value",
      dynamicLabel: "",
      resultStorage: [],
      iccidRule: [v => !!v || "Obrigatório"],
      loadingLinePackages: false,
    };
  },

  async mounted() {
    this.$emit('componentLoading', true);
    if (this.page === 'interaction') {
      await this.loadSolicitationValidateFields(
          this.$route.params.id
      ).catch(error => {
        this.$swal("Oops...", error.message, "error");
        this.$router.push({
          name: "solicitation"
        });
      });
    }
    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    if (localStorage.getItem(this.storageKey) && this.page !== 'validate' && this.page !== 'interaction') {
      let getData = localStorage.getItem(this.storageKey);
      this.resultStorage = JSON.parse(getData);
      this.lineSelect = Object.assign([], this.resultStorage);
    }

    if (this.wizard.model["change_chip"]) {
      this.wizard.forms.map(form => {
        if (form.type === "change_chip") this.dynamicLabel = form.label;
      });
    }

    if (!this.lineSelect.length && this.page !== 'validate' && this.page !== 'interaction') {
      await this.clickAddNewLine();
    }

    if (this.page === 'validate' || this.page === 'interaction') {
      const fieldsSolicitation = this.selected_solicitation_validate_fields.data;
      if (fieldsSolicitation !== undefined) {
        let valuesChangeChip = [];
        fieldsSolicitation.forEach(e => {
          if (e.name === 'change_chip') {
            valuesChangeChip = e.values;
          }
        });

        this.lineSelect = valuesChangeChip;
      }
    }

    this.desactiveComponentNewIccid = this.isGroupPessoa ?? false;
    this.loading = false;
    this.$emit('componentLoading', false);
  },

  computed: {
    ...mapState("packages", ["lines"]),
    ...mapState("forms", ["wizard"]),
    ...mapState("solicitation", ["selected_solicitation_validate_fields","solicitation_validate_values"]),
    ...mapGetters({
      linePackages: "packages/getLinePackages"
    }),

    isReadonlyByPage() {
      return this.page === 'interaction';
    },

    disabledBtnStyle: function () {
      return this.unremovable ? "color: transparent !important" : undefined;
    },
  },

  methods: {
    ...mapActions("packages", ["loadLines", "loadLinePackages"]),
    ...mapMutations({ setModel: "forms/setModelCancelLine" }),
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("solicitation", ["setChangeChipComponentValues","loadSolicitationValidateFields","setAutomationComponentValues"]),
    ...mapActions("interaction", ["setAutomationComponentFields"]),

    saveComponentField(data, component, index) {
      this.lineSelect[index][component] = data;
    },

    async clickAddNewLine() {
      const newLine = { line: {}, iccid: {}, customIccid: '' };
      this.lineSelect.push(newLine);
    },

    updateValues(cancelLineList) {
      const payload = {
        step: this.index,
        selected: cancelLineList
      };
      this.setModel(payload);
      localStorage.setItem(this.storageKey, JSON.stringify(cancelLineList));
    },

    closeLine(index) {
      if (index > -1) {
        this.lineSelect.splice(index, 1);
      }
      this.updateValues(this.lineSelect);
    },

    customFilter(item, queryText) {
      const textOne = item.nr_linha.toLowerCase();
      const textTwo = item.nome_completo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    }
  },

  watch: {
    lineSelect: {
      deep: true,
      /* A cada interação com o lineselect salva no local storage */
      handler: function (values) {
        if (this.page === 'validate') {
          let values_fields = {...this.solicitation_validate_values};
          values_fields.change_chip = values;
          this.setAutomationComponentValues({
            automation: "change_chip",
            data: values_fields
          });
          this.SET_FORM_EMAIL({});
          this.SET_FORM_EMAIL(this.solicitation_validate_values);
        } else if (this.page === 'interaction'){
          this.setAutomationComponentFields({
            automation: "change_chip",
            data: values
          });
        } else {
          const payload = {
            step: this.index,
            selected: values
          };
          this.setModel(payload);
          localStorage.setItem(this.storageKey, JSON.stringify(values));
        }
      }
    }
  }
};
</script>

<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
