<template>
  <div>
    <!-- Bloco de interações -->
    <v-row class="col-md-12">
      <v-row class="col-md-3">
        <v-btn color="primary" @click="updateCard" v-if="!isLastInteractionFinal" :retain-focus-on-click="false" :ripple="false">
          {{ show ? 'Cancelar envio' : 'Adicionar interação' }}
        </v-btn>
      </v-row>
    </v-row>

    <v-card class="mx-auto" :disabled="loadingFields" outlined>

      <v-spacer></v-spacer>

      <v-expand-transition>
        <div v-show="show">
          <v-container>
            <!-- Bloco de select -->
            <v-card outlined class="ml-6 mr-6">
              <v-row>
                <v-col class="ml-6 mr-6">
                  <v-select
                    :items="solicitation_status.data"
                    item-text="display_name"
                    v-model="status"
                    item-value="id"
                    label="Selecionar status"
                    outlined
                    @change="fieldsReload($event)"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card>
            <v-card-text class="text--primary" v-if="status !== 'Selecionar status' && !loadingFields">
              <!-- Bloco de visibilidade -->
              <v-card v-if="!selected_solicitation.is_group_pessoa" outlined class="ml-2 mr-2">
                <v-card-text class="bottom-box">
                  <v-row>
                    <v-col class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                      <v-select
                        :items="setOptions"
                        item-text="label"
                        item-value="option"
                        label="Informar Visibilidade"
                        v-model="visibility"
                        dense
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                      <v-combobox
                        multiple
                        dense
                        v-model="emails"
                        label="E-mails"
                        append-icon
                        outlined
                        chips
                        small-chips
                        deletable-chips
                        :items="emailList"
                        item-text="name"
                        return-object
                        @change="selectedEmailsUpdate(emails)"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- Bloco de campos customizados -->
              <v-card outlined class="ml-3 mr-3 mt-4 pa-3">
                <v-row>
                  <v-col
                    v-for="(field, i) in fields_vuetify"
                    :key="i"
                    class="d-flex col-sm-12 col-md-3 col-lg-3 col-xl-3 mb-0 pb-0"
                    style="display: contents"
                  >
                    <component
                      v-if="
                        field.type !== 'TextAreaComponent' &&
                          field.type !== 'FileComponent' &&
                          field.type !== 'Plans' &&
                          field.type !== 'Pacotes' &&
                          field.type !== 'ChangeDDD' &&
                          field.type !== 'LineDisablePackage' &&
                          field.type !== 'CancelLineComponent' &&
                          field.type !== 'ChangeChipComponent' &&
                          field.type !== 'SuspendLineComponent' &&
                          field.type !== 'PackagesMultipleComponent' &&
                          field.type !== 'ReactivateLineComponent' &&
                          field.type !== 'VoluntaryLineSuspensionComponent' &&
                          field.type !== 'OnlineManagerComponent' &&
                          field.type !== 'ActivateNewLineComponent'
                      "
                      dense
                      outlined
                      rows="2"
                      :is="field.type"
                      :label="field.label"
                      :name="field.name"
                      :plans="field"
                      :pacotes="field"
                      :field="field"
                      :page="page"
                      @input="setFieldValue($event, field)"
                    ></component>
                  </v-col>
                  <v-col
                    v-for="field in fields_vuetify"
                    :key="field.name"
                    class="d-flex col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 mb-0 pt-0 pb-0"
                  >
                    <component
                      v-if="
                        field.type === 'TextAreaComponent' ||
                          field.type === 'FileComponent' ||
                          field.type === 'Plans' ||
                          field.type === 'Pacotes' ||
                          field.type === 'ChangeDDD' ||
                          field.type === 'LineDisablePackage' ||
                          field.type === 'CancelLineComponent' ||
                          field.type === 'ChangeChipComponent' ||
                          field.type === 'SuspendLineComponent' ||
                          field.type === 'PackagesMultipleComponent' ||
                          field.type === 'ReactivateLineComponent' ||
                          field.type === 'VoluntaryLineSuspensionComponent' ||
                          field.type === 'OnlineManagerComponent' ||
                          field.type === 'ActivateNewLineComponent'
                      "
                      dense
                      outlined
                      rows="2"
                      :is="field.type"
                      :label="field.type"
                      :name="field.name"
                      :plans="field"
                      :pacotes="field"
                      :field="field"
                      :page="page"
                      @componentLoading="componentLoading"
                      @input="setFieldValue($event, field)"
                    ></component>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="salvarInteracao"
                :loading="creating || loadingComponent"
                :disabled="status === 'Selecionar status' && !creating"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-container>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { VTextField, VTextarea, VFileInput } from "vuetify/lib";
import Plans from "@/components/interaction/interactionPlansComponent";
import Pacotes from "@/components/interaction/interactionPackagesComponent";
import ChangeDDD from "@/components/interaction/interactionChangeDDDComponent";
import RemovePackages from "@/components/interaction/interactionRemovePackagesComponent";
import CancelLine from "@/components/interaction/interactionCancelLineComponent";
import PackagesMultipleComponent from "@/components/customFieldsComponents/PackagesMultipleComponent";
import LineDisablePackage from "@/components/customFieldsComponents/LineDisablePackageComponent";
import CancelLineComponent from "@/components/customFieldsComponents/CancelLineComponent";
import SuspendLineComponent from "@/components/customFieldsComponents/SuspendLineComponent";
import ReactivateLineComponent from "@/components/customFieldsComponents/ReactivateLineComponent";
import VoluntaryLineSuspensionComponent from "@/components/customFieldsComponents/VoluntaryLineSuspensionComponent";
import ChangeChipComponent from "@/components/forms/CustomChangeChipComponent";
import OnlineManagerComponent from "@/components/customFieldsComponents/OnlineManagerComponent";
import ActivateNewLineComponent from "@/components/customFieldsComponents/ActivateNewLineComponent";
import TextAreaComponent from "@/components/forms/CustomTextAreaComponent";
import FileComponent from "@/components/forms/CustomFileComponent";
import CustomTimeComponent from "@/components/forms/CustomTimeComponent";
import CustomDateTimeComponent from "@/components/forms/CustomDateTimeComponent";
import CurrencyInput from "../currency/CurrencyInput.vue";
import Swal from "sweetalert2";

export default {
  components: {
    CurrencyInput,
    VTextField,
    VTextarea,
    VFileInput,
    Plans,
    Pacotes,
    ChangeDDD,
    RemovePackages,
    CancelLine,
    PackagesMultipleComponent,
    LineDisablePackage,
    CancelLineComponent,
    SuspendLineComponent,
    ReactivateLineComponent,
    VoluntaryLineSuspensionComponent,
    ChangeChipComponent,
    OnlineManagerComponent,
    ActivateNewLineComponent,
    TextAreaComponent,
    FileComponent,
    CustomTimeComponent,
    CustomDateTimeComponent
  },
  props: ["fontSize"],
  data() {
    return {
      selected_solicitation: {},
      currentStatus: {},
      emails: [],
      interactionField: [],
      visibility: "public",
      creating: false,
      loadingFields: true,
      loadingComponent: false,
      status: "Selecionar status",
      page: "interaction",
      show: false,
      showFormParams: {
        waiting: "d0ad29e0-fc41-40b8-b5e3-872719f349dd",
        cancelled: "c5bcd554-b188-4f76-9bc7-e5a105a6b79f",
        finished: "7c4b1588-07b7-4047-aae8-39db6870eb68"
      },
      setOptions: [
        { option: "public", label: "Público" }
      ],
      showForm: true
    };
  },
  async mounted() {
    await this.resetFields();
    await this.loadSolicitationStatus().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    await this.loadEmails({
      id: this.$route.params.id
    }).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    this.selected_solicitation = this.getSelectedSolicitation;
    this.currentStatus = this.selected_solicitation.status_id;

    await this.loadInteractionFields({
      status_id: this.solicitation_status.data[0].id,
      solicitation_id: this.selected_solicitation.id
    }).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.interactionField = this.interaction_field.data;

    if (this.currentStatus !== this.showFormParams.cancelled && this.currentStatus !== this.showFormParams.finished) {
      this.currentStatus = this.showFormParams.waiting;
      this.showForm = false;
    }

    if (this.selected_solicitation.is_adm) {
      this.setOptions = [
        { option: "public", label: "Público" },
        { option: "private", label: "Privado" }
      ]
    }

    this.loadingFields = false;
  },
  computed: {
    ...mapState("interaction", [
      "interaction_field",
      "interaction_save",
      "fields",
      "saveIsValid",
      "activate_new_line",
      "emailList",
      "checkNewLine"
    ]),
    ...mapState("solicitation", [
      "solicitation_status",
      "packages_to_disable_component_values",
      "online_manager_component_value",
    ]),
    ...mapGetters("solicitation", ["getSelectedSolicitation"]),
    ...mapGetters("interaction", ["isLastInteractionFinal"]),
    fields_vuetify() {
      return [...this.interactionField].map(function(field) {
        switch (field.type) {
          case "textarea":
            field.type = "TextAreaComponent";
            break;
          case "text":
            field.type = "VTextField";
            break;
          case "file":
            field.type = "FileComponent";
            break;
          case "time":
            field.type = "CustomTimeComponent";
            break;
          case "datetime":
            field.type = "CustomDateTimeComponent";
            break;
          case "plans":
            field.type = "Plans";
            break;
          case "busca_pacote":
            field.type = "Pacotes";
            break;
          case "troca_ddd":
            field.type = "ChangeDDD";
            break;
          case "line_disable_package":
            field.type = "LineDisablePackage";
            break;
          case "cancel_line":
            field.type = "CancelLineComponent";
            break;
          case "change_chip":
            field.type = "ChangeChipComponent";
            break;
          case "suspend_line":
            field.type = "SuspendLineComponent";
            break;
          case "roaming":
            field.type = "PackagesMultipleComponent";
            break;
          case "reactivate_line":
            field.type = "ReactivateLineComponent";
            break;
          case "voluntary_line_suspension":
            field.type = "VoluntaryLineSuspensionComponent";
            break;
          case "suspensao_perda_roubo":
            field.type = "VoluntaryLineSuspensionComponent";
            break;
          case "update_gol":
            field.type = "OnlineManagerComponent";
            break;
          case "activate_new_line":
            field.type = "ActivateNewLineComponent";
            break;
          default:
            field.type = "VTextField";
        }
        return field;
      });
    }
  },
  methods: {
    ...mapActions("interaction", ["loadInteractionFields", "saveInteraction", "updateFields", "loadEmails", "loadCheckNewLine", "resetFields"]),
    ...mapActions("solicitation", ["loadSolicitationStatus"]),
    componentLoading(value) {
      this.loadingComponent = value;
    },
    async salvarInteracao() {

      this.creating = true;
      let fields = this.fields;

      //Gestor Online
      if (this.online_manager_component_value.length) {
        fields = {
          ...fields,
          update_gol: this.online_manager_component_value
        };
      }
      //Ativação de Nova Linha
      if (this.fields.activate_new_line && this.fields.activate_new_line.length) {
        if (this.currentStatus === "7c4b1588-07b7-4047-aae8-39db6870eb68") { // interação finalizar
          await this.loadCheckNewLine({fields}).catch(error => {
            this.$swal("Oops...", error.message, "error");
          });

          let response = true;
          if (this.checkNewLine !== null) {
            response = await this.$swal({
              icon: "error",
              title: "Oops...",
              html: this.checkNewLine,
              showCancelButton: true,
              confirmButtonText: "Corrigir",
              cancelButtonText: "Prosseguir",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#445E93",
              showLoaderOnConfirm: true,
              allowOutsideClick: false
            }).then(result => {
              if (result.value) {
                this.creating = false;
                return false;
              }else {
                return true;
              }
            });
          }

          if (!response) {
            return false;
          }
        }
      }
      //Nova linha
      if (Object.keys(this.activate_new_line).length) {
        this.removeOptions(this.activate_new_line);
        this.removeReadonly(this.activate_new_line);
      }
      const params = {
        emails: this.emails,
        solicitation_id: this.selected_solicitation.id,
        status_id: this.currentStatus,
        visibility: this.visibility,
        fields
      };

      try {
        await this.saveInteraction(params)
        this.emails = [];
        if (this.interaction_save.success) {
          await this.$swal(this.interaction_save.message, "", "success");
          //Se for finalização e houve sucesso redirecionar para listagem de solicitações
          if (this.currentStatus === "7c4b1588-07b7-4047-aae8-39db6870eb68") {
            await this.$router.push({
              name: "solicitation"
            });
          }

          await this.fieldsReload();
        }
      } catch (error) {
        await this.$swal("Oops...", error.message, "error");
      }
      this.creating = false;
    },

    setFieldValue(value, field) {
      field.value = value;
      this.updateFields(field);
    },

    async fieldsReload(statusId) {
      if (statusId) {
        this.currentStatus = statusId;
      }
      this.loadingFields = true;
      await this.loadInteractionFields({
        status_id: this.currentStatus,
        solicitation_id: this.selected_solicitation.id
      }).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.interactionField = this.interaction_field.data;
      this.loadingFields = false;
    },

    async updateCard() {
      this.show = !this.show;

      if (this.selected_solicitation.is_group_pessoa) {
        this.status = "d0ad29e0-fc41-40b8-b5e3-872719f349dd";
      }
    },

    removeOptions(field) {
      const plansName = Object.keys(field);
      plansName.map(plan => {
        field[plan].map(e => {
          if (e.options) {
            delete e.options;
          }
        });
      });
    },

    removeReadonly(field) {
      const plansName = Object.keys(field);
      plansName.map(plan => {
        field[plan].map(e => {
          if (e.readonly === true || e.readonly === false) {
            delete e.readonly;
          }
        });
      });
    },

    selectedEmailsUpdate(value) {
      value.map(val => {
        if (typeof val != "object") {
          value.push({
            id: "",
            name: val,
            email: val
          });
          value = value.filter(el => el !== val);
        }
      });
      this.emails = [];
      value.map(val => {
        this.emails.push(val);
      });
    }
  }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  width: 100%;
  display: block;
  justify-content: center;
}

.form-title::before {
  background: white;
}

.selector-box {
  background-color: whitesmoke;
}

.selector-col {
  text-align: center;
  background: transparent;
}

.selector-button {
  margin: 3px;
  font-size: 0.7rem;
  width: 90%;
  border: thin solid #4d638c;
  color: #4d638c;
}

.selector-button:hover {
  background: rgb(240, 240, 240);
}

.bottom-box {
  padding: 5px 15px 0px 15px;
}

.custom-buttons {
  font-size: 0.8rem;
  padding: 8px;
}

@media (max-width: 600px) {
  .v-card__title {
    font-size: 1.5em;
  }
  .selector-button {
    font-size: 0.7rem;
  }

  .last-mobile {
    margin-bottom: 50px;
  }
}

@media (min-width: 601px) {
  .v-card__title {
    font-size: 2.2em;
  }

  .selector-button {
    font-size: 0.7rem;
  }
}

.v-card__title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.form-title::before {
  background: white;
}

.selector-box {
  background-color: whitesmoke;
}

.selector-col {
  text-align: center;
  background: transparent;
}

.selector-button {
  margin: 3px;
  font-size: 0.7rem;
  width: 90%;
  border: thin solid #4d638c;
  color: #4d638c;
}

.selector-button:hover {
  background: rgb(240, 240, 240);
}

.bottom-box {
  padding: 5px 15px 5px 15px;
}

.custom-buttons {
  font-size: 0.8rem;
  padding: 8px;
}

@media (max-width: 600px) {
  .v-card__title {
    font-size: 1.5em;
  }
  .selector-button {
    font-size: 0.7rem;
  }

  .last-mobile {
    margin-bottom: 50px;
  }
}

@media (min-width: 601px) {
  .v-card__title {
    font-size: 2.2em;
  }

  .selector-button {
    font-size: 0.7rem;
  }
}
</style>
